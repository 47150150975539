import { Col, Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import TripAnomaliesAPI from '../../api/trip-anomalies';
import MapLoader from '../../components/MapLoader';
import LinkText from '../../components/Text/LinkText';
import { getCenterCoordinates, getStaticMapImageURL } from '../../utils/map';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { getActiveRoutes } from '../../views/trips/trips-page-helpers';

const TripAnomaliesMap = props => {
  const { t, tripAnomaly, onViewFullScreenClick } = props;

  const [activeRoutes, setActiveRoutes] = useState([]);
  const [mapCenter, setMapCenter] = useState();

  const anomalyCoordinatesQuery = useQuery({
    enabled: !!get(tripAnomaly, '_id'),
    staleTime: getMinutesAsMilliseconds(5),
    queryKey: ['fetchTripAnomalyCoordinates', get(tripAnomaly, '_id')],
    queryFn: () => new TripAnomaliesAPI().fetchTripAnomalyCoordinates(get(tripAnomaly, '_id')),
  });

  const map = useMemo(() => {
    if (anomalyCoordinatesQuery.isFetching || !anomalyCoordinatesQuery.data) return null;

    let routes, center;
    let mapHasError;

    try {
      routes = getActiveRoutes(anomalyCoordinatesQuery.data);
      mapHasError = !routes.length;

      if (!mapHasError) {
        const centerConfig = getCenterCoordinates(
          anomalyCoordinatesQuery.data.journeyGeoJson.geometry.coordinates,
        );
        center = centerConfig.center;
        setMapCenter(center);
        setActiveRoutes(routes);
      }
    } catch (error) {
      mapHasError = true;
    }

    return mapHasError ? (
      <Row
        justify="center"
        align="middle"
        style={{ width: 563, height: 304, background: '#F6F7F8' }}
      >
        <Col flex="179px">
          <MapLoader text={t('errorLoadingMap')} />
        </Col>
      </Row>
    ) : (
      <img
        alt="Trip map"
        style={{
          cursor: typeof onViewFullScreenClick === 'function' ? 'pointer' : 'initial',
          width: '563px',
          height: '304px',
        }}
        src={getStaticMapImageURL(
          routes,
          anomalyCoordinatesQuery.data.journeyGeoJson,
          563,
          304,
          true,
        )}
        width="100%"
        height="100%"
        title={typeof onViewFullScreenClick === 'function' ? t('viewDetailedRoute') : undefined}
        onClick={() => {
          if (typeof onViewFullScreenClick === 'function') {
            onViewFullScreenClick(tripAnomaly, routes, center);
          }
        }}
      />
    );
  }, [
    t,
    tripAnomaly,
    onViewFullScreenClick,
    anomalyCoordinatesQuery.data,
    anomalyCoordinatesQuery.isFetching,
  ]);

  if (!activeRoutes.length || !mapCenter || anomalyCoordinatesQuery.isFetching || !tripAnomaly)
    return (
      <Row
        justify="center"
        align="middle"
        style={{ width: 563, height: 304, background: '#F6F7F8' }}
      >
        <Col flex="179px">
          <MapLoader text={t('loadingMap')} />
        </Col>
      </Row>
    );

  return (
    <Space direction="vertical" size="small">
      <div>{map}</div>
      {typeof onViewFullScreenClick === 'function' && (
        <LinkText
          onClick={() => {
            onViewFullScreenClick(tripAnomaly, activeRoutes, mapCenter);
          }}
        >
          {t('viewDetailedRoute')}
        </LinkText>
      )}
    </Space>
  );
};

export default TripAnomaliesMap;
