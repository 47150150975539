import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

import { STATUS_LIST } from '../../enum';
import Tag from './index';

/**
 * Tag component to represent any Status
 *
 * @param {object} props - Component props
 */
const StatusTag = props => {
  const { t, status, size, ...rest } = props;

  if (!status) {
    return (
      <Tag size={size} variant="red">
        {t('empty')}
      </Tag>
    );
  }

  const config = STATUS_LIST().StatusTagColorConfig[status.toLowerCase()];

  if (!config) {
    return (
      <Tag size={size} variant="red">
        {t('invalid')}: {status}
      </Tag>
    );
  }

  return (
    <Tag {...rest} size={size} variant={config.variant}>
      {config.label}
    </Tag>
  );
};

StatusTag.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  status: PropTypes.string,
};

StatusTag.defaultProps = {
  size: 'medium',
};

export default withNamespaces()(StatusTag);
