import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import AxleAPI from '../../api/axle';
import useModal from '../../hooks/useModal';
import { momentFormat, setModalFullscreenWidth } from '../../utils/common';
import { selectStoreCompanySettings, useStoreSelector } from '../../utils/storeSelectors';
import InsuranceMinimumCollapsibleSection from '../../views/userdetails/insurance-info/InsuranceMinimumCollapsibleSection';
import Button from '../Button';
import ConfirmModal from '../Modal/ConfirmModal';
import SpaceSpinner from '../SpaceSpinner';
import Text from '../Text';
import Toast from '../Toast';
import SelectInsuranceCountry from './SelectInsuranceCountry';
import className from './styles.module.scss';
import UserInsuranceContent from './UserInsuranceContent';

const AxleIframeSection = React.lazy(() => import('./AxleIframeSection'));
const AxleContent = React.lazy(() => import('./AxleInsuranceContent'));
const MeasureOneContent = React.lazy(() => import('./MeasureOneInsuranceContent'));

const UserInsuranceSettingFormContent = props => {
  const {
    t,
    insurancePolicy,
    measureOneInsurancePolicy,
    canUpdateInsuranceDoc,
    axleInsurancePolicy,
    user,
    isSubmitting,
    onSubmit,
    disabled,
    userComplianceDetails,
    onConfirm,
    insuranceCoverages,
    ...rest
  } = props;

  const queryClient = useQueryClient();
  const companySettings = useStoreSelector(selectStoreCompanySettings);
  const [axleIgnitionData, setAxleIgnitionData] = useState({
    ignitionToken: null,
    ignitionUri: null,
  });

  const [isInsuranceFromUSA, setIsInsuranceFromUSA] = useState();
  const [isManualInsuranceEnabled, setIsManualInsuranceEnabled] = useState(false);
  const [isSelectingInsuranceCountry, setIsSelectingInsuranceCountry] = useState(false);
  const [isProgressiveInsurance, setIsProgressiveInsurance] = useState(false);

  const [isAxleSuccessModalVisible, openAxleSuccessModal, closeAxleSuccessModal] = useModal();

  const handleAxleSuccessModalClose = useCallback(() => {
    closeAxleSuccessModal();

    queryClient.resetQueries(['fetchAxleInsuranceDocumentsInformation'], {
      exact: false,
      active: true,
    });
  }, [closeAxleSuccessModal, queryClient]);

  const createAxleClientMutation = useMutation(
    () => {
      setIsManualInsuranceEnabled(false);
      setIsInsuranceFromUSA(true);

      return AxleAPI.startIgnition();
    },
    {
      onSuccess: data => {
        setAxleIgnitionData(data);
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const displayUserInsuranceSection = useMemo(() => {
    if (isManualInsuranceEnabled) return true;
    if (isSelectingInsuranceCountry && typeof isInsuranceFromUSA !== 'boolean') return false;
    if (
      companySettings.axleIntegration &&
      !!insurancePolicy &&
      !axleInsurancePolicy &&
      !measureOneInsurancePolicy
    )
      return true;

    return !companySettings.axleIntegration;
  }, [
    isSelectingInsuranceCountry,
    isInsuranceFromUSA,
    isManualInsuranceEnabled,
    insurancePolicy,
    axleInsurancePolicy,
    measureOneInsurancePolicy,
    companySettings.axleIntegration,
  ]);

  const displayMOInsuranceSection = useMemo(() => {
    if (isSelectingInsuranceCountry && typeof isInsuranceFromUSA !== 'boolean') return false;
    return !displayUserInsuranceSection && !!companySettings.axleIntegration;
  }, [
    isSelectingInsuranceCountry,
    isInsuranceFromUSA,
    displayUserInsuranceSection,
    companySettings,
  ]);

  useEffect(() => {
    if (
      isSelectingInsuranceCountry &&
      !isInsuranceFromUSA &&
      typeof isInsuranceFromUSA === 'boolean'
    ) {
      setIsManualInsuranceEnabled(false);
      setIsSelectingInsuranceCountry(false);
      setIsInsuranceFromUSA();
    }
    // eslint-disable-next-line
  }, [insurancePolicy]);

  if (createAxleClientMutation.isLoading) {
    return (
      <div className={className.fullscreenWrapper}>
        <SpaceSpinner />
      </div>
    );
  }

  if (axleIgnitionData.ignitionToken) {
    return (
      <AxleIframeSection
        {...axleIgnitionData}
        onError={errorMessage => {
          setAxleIgnitionData({
            ignitionToken: null,
            ignitionUri: null,
          });

          Toast({
            type: 'error',
            message: t(errorMessage),
          });
        }}
        onSuccess={() => {
          setAxleIgnitionData({
            ignitionToken: null,
            ignitionUri: null,
          });
          setIsManualInsuranceEnabled(false);
          setIsSelectingInsuranceCountry(false);

          openAxleSuccessModal();
        }}
        onExit={() => {
          setIsInsuranceFromUSA();
          setIsSelectingInsuranceCountry(false);
          setAxleIgnitionData({
            ignitionToken: null,
            ignitionUri: null,
          });
        }}
      />
    );
  }

  return (
    <Space direction="vertical" size="large" {...rest}>
      {!isSelectingInsuranceCountry && !isManualInsuranceEnabled && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col flex="500px">
            <InsuranceMinimumCollapsibleSection t={t} insuranceCoverages={insuranceCoverages} />
          </Col>

          {companySettings.axleIntegration && !disabled && (
            <Col style={{ paddingTop: 3 }}>
              <Button
                size="sm"
                loading={createAxleClientMutation.isLoading}
                disabled={createAxleClientMutation.isLoading}
                onClick={() => setIsSelectingInsuranceCountry(true)}
              >
                {t('uploadInsuranceInformation')}
              </Button>
            </Col>
          )}
        </Row>
      )}

      {isSelectingInsuranceCountry && (
        <Row justify="end">
          <Col>
            <Button
              size="sm"
              type="secondary"
              onClick={() => {
                setIsInsuranceFromUSA();
                setIsManualInsuranceEnabled(false);
                setIsSelectingInsuranceCountry(false);
              }}
            >
              {t('cancelSelection')}
            </Button>
          </Col>
        </Row>
      )}

      {isSelectingInsuranceCountry && !isSubmitting && (
        <SelectInsuranceCountry
          t={t}
          isUSAInsurance={isInsuranceFromUSA}
          onUsaCaClick={createAxleClientMutation.mutateAsync}
          onOtherCountryClick={() => {
            setIsManualInsuranceEnabled(true);
            setIsInsuranceFromUSA(false);
          }}
        />
      )}

      {!!displayUserInsuranceSection && (
        <UserInsuranceContent
          t={t}
          user={user}
          insurancePolicy={insurancePolicy}
          disabled={disabled}
          isManualInsuranceEnabled={isManualInsuranceEnabled}
          isSubmitting={isSubmitting}
          userComplianceDetails={userComplianceDetails}
          onSubmit={onSubmit}
          onConfirm={onConfirm}
          isSelectingInsuranceCountry={isSelectingInsuranceCountry}
        />
      )}

      {displayMOInsuranceSection && !axleInsurancePolicy && !!measureOneInsurancePolicy && (
        <MeasureOneContent
          t={t}
          user={user}
          measureOneInsurancePolicy={measureOneInsurancePolicy}
          setIsProgressiveInsurance={setIsProgressiveInsurance}
          isProgressiveInsurance={isProgressiveInsurance}
          isSelectingInsuranceCountry={isSelectingInsuranceCountry}
          canUpdateInsuranceDoc={canUpdateInsuranceDoc}
        />
      )}

      {displayMOInsuranceSection &&
        (!!axleInsurancePolicy || (!measureOneInsurancePolicy && !axleInsurancePolicy)) && (
          <AxleContent
            t={t}
            user={user}
            axleInsurancePolicy={axleInsurancePolicy}
            isSelectingInsuranceCountry={isSelectingInsuranceCountry}
            canUpdateInsuranceDoc={canUpdateInsuranceDoc}
          />
        )}

      <ConfirmModal
        centered
        hideCancel
        title=" "
        onOk={handleAxleSuccessModalClose}
        bodyStyle={{ height: '100%' }}
        width={setModalFullscreenWidth(535)}
        visible={isAxleSuccessModalVisible}
        onCancel={handleAxleSuccessModalClose}
        message={
          <Space direction="vertical" size="middle">
            <Text textAlign="center">
              {t('thankYouForProvidingInsuranceInfo__date', {
                date: momentFormat(new Date(), 'MM/DD/YYYY'),
              })}
            </Text>

            <Text textAlign="center">{t('infoWillDisplayWhenReceivedFromAxle')}</Text>
          </Space>
        }
      />
    </Space>
  );
};

export default withNamespaces()(UserInsuranceSettingFormContent);
