import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import ReactCalendar from 'react-calendar';

import { momentCompanyTimezone } from '../../utils/common';
import Tooltip from '../Tooltip';
import classNames from './style.module.scss';

/**
 * Calendar component
 *
 * @param {Object} props - The component props.
 * @param {Array<{date: string, description: string, color: string}>} props.selectedDates - An array of objects containing dates, their descriptions and color codes.
 */
const Calendar = ({ selectedDates }) => {
  /**
   * State to manage the visibility of tooltips.
   */
  const [visibleTooltips, setVisibleTooltips] = useState({});

  /**
   * Converts a date string into a Date object without timezone information.
   *
   * @param {String} date - The date string to convert.
   * @returns {Date} - A Date object without timezone information.
   */
  const convertDateStringIntoDateObj = useCallback(date => {
    const dateNoTimzone = momentCompanyTimezone(date, 'MM/DD/YYYY HH:mm:ss');
    return new Date(dateNoTimzone.year(), dateNoTimzone.month(), dateNoTimzone.date());
  }, []);

  /**
   * Convert selectedDates into an array of Date objects.
   *
   * @type {Array<Date>} - An array of Date objects without timezone information.
   */
  const datesArray = useMemo(() => {
    const array = [];

    selectedDates.forEach(data => {
      array.push(convertDateStringIntoDateObj(data.date));
    });

    return array;
  }, [selectedDates, convertDateStringIntoDateObj]);

  /**
   * Finds a selected date based on a given date.
   *
   * @param {Date} date - The date to find.
   * @returns {(Object|undefined)} - The found date object or undefined if not found.
   */
  const getSelectedDate = useCallback(
    date => {
      return selectedDates.find(data => {
        const currentDate = moment(convertDateStringIntoDateObj(data.date));
        return moment(date).isSame(currentDate, 'date');
      });
    },
    [selectedDates, convertDateStringIntoDateObj],
  );

  /**
   * Checks if a date is selected.
   *
   * @param {Date} date - The date to check.
   * @returns {Boolean} - True if the date is selected, false otherwise.
   */
  const isDateSelected = useCallback(
    date => {
      return datesArray.some(d => moment(d).isSame(date, 'date'));
    },
    [datesArray],
  );

  /**
   * Custom tile class names for ReactCalendar.
   *
   * @param {Object} props - The tile properties.
   * @returns {Array} - An array of class names.
   */
  const tileClassName = useCallback(
    ({ date }) => {
      const classes = [classNames.dayTile];
      // give active days a special class
      const dateIndex = datesArray.findIndex(d => moment(d).isSame(date, 'date'));
      if (dateIndex !== -1) {
        return [
          classNames.activeDay,
          classNames[selectedDates[dateIndex].color || 'green'],
          ...classes,
        ];
      }
      return classes;
    },
    [datesArray, selectedDates],
  );

  const renderTileContent = useCallback(
    ({ date }) => {
      if (!isDateSelected(date)) return null;
      const dateData = getSelectedDate(date);

      if (!dateData?.description) return null;

      return (
        <Tooltip title={dateData.description} key={date} visible={visibleTooltips[date]}>
          <div
            className={classNames.dot}
            onMouseLeave={() => {
              setVisibleTooltips(state => ({
                ...state,
                [date]: false,
              }));
            }}
            onMouseEnter={() => {
              setVisibleTooltips(state => ({
                ...state,
                [date]: true,
              }));
            }}
          />
        </Tooltip>
      );
    },
    [isDateSelected, getSelectedDate, visibleTooltips],
  );

  return (
    <div className={classNames.calendarWrapper}>
      <ReactCalendar
        calendarType="US"
        tileClassName={tileClassName}
        defaultActiveStartDate={new Date()}
        tileContent={renderTileContent}
      />
    </div>
  );
};

Calendar.defaultProps = {
  selectedDates: [],
};

export default Calendar;
