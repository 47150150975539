import { get, isNaN, isObjectLike } from 'lodash';

import { STATUS_LIST, TRIP_SOURCES } from '../enum';
import { momentFormat } from './common';
import { getMomentDateWithoutTimezone } from './datetime';
import { formatNumberToLocale } from './numbers';
import { selectStoreCountryByCode } from './storeSelectors';

export const getTripCompanyLocationToAddressData = trip => {
  if (trip.companyLocations) {
    if (
      Array.isArray(trip.companyLocations.toAddress) &&
      !!trip.companyLocations.toAddress.length
    ) {
      return trip.companyLocations.toAddress[0];
    } else if (
      trip.companyLocations &&
      isObjectLike(trip.companyLocations.toAddress) &&
      !Array.isArray(trip.companyLocations.toAddress)
    ) {
      return trip.companyLocations.toAddress;
    }
  }
};

export function getTripCompanyLocationToAddressLabel(trip) {
  return getTripCompanyLocationToAddressData(trip)?.label;
}

export function getTripCompanyLocationToAddress(trip) {
  const address = getTripCompanyLocationToAddressData(trip)?.address;
  return address?.formattedAddress;
}

export const getTripCompanyLocationFromAddressData = trip => {
  if (trip.companyLocations) {
    if (
      Array.isArray(trip.companyLocations.fromAddress) &&
      !!trip.companyLocations.fromAddress.length
    ) {
      return trip.companyLocations.fromAddress[0];
    } else if (
      isObjectLike(trip.companyLocations.fromAddress) &&
      !Array.isArray(trip.companyLocations.fromAddress)
    ) {
      return trip.companyLocations.fromAddress;
    }
  }
};

export function getTripCompanyLocationFromAddressLabel(trip) {
  return getTripCompanyLocationFromAddressData(trip)?.label;
}

export function getTripCompanyLocationFromAddress(trip) {
  const address = getTripCompanyLocationFromAddressData(trip)?.address;
  return address?.formattedAddress;
}

/**
 * Normalizes the Trips data from the API
 * in order to be used in Tables or any other parts of the site
 *
 * @param {object} trp Trip data
 * @param {object=} submittedBy Custom trip's driver data. Should follow the user's schema.
 */
export function normalizeTripsSchema(trp, submittedBy) {
  let optimalMileage = '';

  let user = {};
  let group = [];

  if (submittedBy) {
    user = submittedBy;
    group = submittedBy.group;
  } else if (Array.isArray(trp.user)) {
    user = trp.user[0];
    group = trp.group;
  } else if (trp.user && trp.user._id) {
    // Some endpoints do return the user in an Array
    user = trp.user;
    group = trp.group;
  }

  if (!trp.gpsVerified) {
    let optimalMileageRaw = trp?.updatedAddress?.distance
      ? trp.updatedAddress.distance.routes.car.distance.value
      : trp.journeyDistance;
    optimalMileage = Number(optimalMileageRaw) >= 0.1 ? formatNumberToLocale(optimalMileageRaw) : 0;
  }

  const crmMatch = !!(
    getTripCompanyLocationToAddressLabel(trp) || getTripCompanyLocationFromAddressLabel(trp)
  );

  return {
    ...trp,
    user,
    crmMatch,
    _id: trp._id,
    isAnonymized: user.isAnonymized,
    tripType: trp.tripType,
    normalized: true,
    key: trp._id,
    driverID: user._id,
    profilePicture: user.profilePicture,
    firstname: user.firstName,
    lastname: user.lastName,
    address: trp.address,
    from_loc: getTripCompanyLocationFromAddress(trp)
      ? getTripCompanyLocationFromAddress(trp)
      : trp.tripFromAddressLabel
      ? trp.tripFromAddressLabel
      : Array.isArray(trp.address) && !!trp.address.length
      ? get(trp.address[0], 'formattedAddress', trp.address[0])
      : '-',
    to_loc: getTripCompanyLocationToAddress(trp)
      ? getTripCompanyLocationToAddress(trp)
      : trp.tripToAddressLabel
      ? trp.tripToAddressLabel
      : Array.isArray(trp.address) && !!trp.address.length
      ? get(trp.address[1], 'formattedAddress', trp.address[1])
      : '-',
    journeyStartTs: trp.journeyStartTs,
    journeyEndTs: trp.journeyEndTs,
    date: momentFormat(trp.journeyStartTs, 'MM/DD/YYYY zz'),
    dateNoTimezone: getMomentDateWithoutTimezone(trp.journeyStartTs).format('MM/DD/YYYY'),
    time: momentFormat(trp.journeyEndTs, 'hh.mm.ss A'),
    starttime: momentFormat(trp.journeyStartTs, 'hh.mm.ss A'),
    pricing: trp.pricing,
    amount: Number((trp.pricing || {}).totalAmount || 0).toFixed(2),
    currency: (trp.pricing || {}).currency,
    calculations: (trp.pricing || {}).calculations,
    distanceUnit: typeof trp.journeyDistance === 'string' ? trp.journeyDistance.split(' ')[1] : '',
    optimalMileage: optimalMileage,
    gps: trp.gpsVerified ? 'Yes' : 'No',
    company_loc: trp.company_loc || 'No',
    crm_event: trp.crm_event || 'No',
    commute: trp.tripType === 'commute' ? 'Yes' : 'No',
    status: trp.journeyStatus,
    sourceFrom: trp.sourceFrom,
    journeyGeoJson: trp.journeyGeoJson,
    group,
    expandable: trp.captureMode !== 'Daily-Mileage',
  };
}

/**
 * Provides a summary of the data from all the trips submitted for approval
 *
 * @param {object[]} trips Trips submitted for approval
 * @return {{totalAmount: string, gpsCount: number, nonGpsCount: number, ids: string[], totalDistance: string}}
 */
export const calculateSubmittedTripsTotal = (trips = []) => {
  let selectedTripIDs = [];
  let totalDistance = 0;
  let totalAmount = 0;
  let gpsCount = 0,
    nonGpsCount = 0;

  trips.forEach(trip => {
    const normalizedTrip = trip.normalized ? trip : normalizeTripsSchema(trip, undefined);

    selectedTripIDs.push(normalizedTrip.key);

    if (normalizedTrip.gps === 'Yes') {
      gpsCount++;
    } else {
      nonGpsCount++;
    }

    if (trip.status !== STATUS_LIST().Status.DENIED) {
      totalDistance = parseFloat(totalDistance) + parseFloat(normalizedTrip.journeyDistance);

      let tripAmount = '0';
      if (trip && normalizedTrip.amount) {
        tripAmount = normalizedTrip.amount;
      }
      totalAmount = parseFloat(totalAmount) + parseFloat(tripAmount);
    }
  });

  return {
    // totalDistance: totalDistance.toFixed(2),
    totalAmount: totalAmount.toFixed(2),
    ids: selectedTripIDs,
    gpsCount,
    nonGpsCount,
  };
};

/**
 * Returns a series of percentages related to the Trip's data sources
 *
 * @param {object[]} trips Array of trips
 * @param {boolean} onlyApproved Should the percentages only be calculated for approved or paid Trips?
 * @return {{otherSourcesPercent: string, gpsCount: number, totalTrips: number, manualTripsPercent: string, manualTripsCount: number, otherSourcesTripsCount: number, mobileTripsCount: number, gpsPercent: string, mobileTripsPercent: string}}
 */
export function calculateTripsSourcePercentage(trips = [], onlyApproved = false) {
  let gpsCount = 0;
  let mobileTripsCount = 0;
  let manualTripsCount = 0;
  let vehicleDeviceTripsCount = 0;
  const totalTrips = trips.length;

  const increaseAmounts = trip => {
    if (trip.sourceFrom === TRIP_SOURCES.WEB) {
      manualTripsCount++;
    } else if (trip.sourceFrom === TRIP_SOURCES.MOBILE) {
      mobileTripsCount++;
    } else if (trip.sourceFrom === TRIP_SOURCES.OBD) {
      vehicleDeviceTripsCount++;
    }

    if (trip.gps === 'Yes') gpsCount++;
  };

  trips.forEach(trip => {
    const normalizedTrip = trip.normalized ? trip : normalizeTripsSchema(trip);

    if (onlyApproved) {
      if (
        [STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(normalizedTrip.status)
      ) {
        increaseAmounts(normalizedTrip);
      }
    } else {
      increaseAmounts(normalizedTrip);
    }
  });

  return {
    totalTrips,
    gpsCount,
    manualTripsCount,
    mobileTripsCount,
    vehicleDeviceTripsCount,
    // gpsPercent: formatNumberToLocale((gpsCount / totalTrips) * 100, 0),
    mobileTripsPercent: formatNumberToLocale((mobileTripsCount / totalTrips) * 100, 0),
    manualTripsPercent: formatNumberToLocale((manualTripsCount / totalTrips) * 100, 0),
    vehicleDeviceTripsPercent: formatNumberToLocale(
      (vehicleDeviceTripsCount / totalTrips) * 100,
      0,
    ),
  };
}

/**
 * Turns the Unit API value into a Distance Unit value like mi (miles) or km (kilometers)
 *
 * @param {string} countryCode Country ISO code
 */
export const getDistanceUnitStringValue = (countryCode, useLong = false, capitalize = false) => {
  const country = selectStoreCountryByCode(countryCode);

  if (country) {
    const distanceUnit = useLong ? country.distanceLong : country.distanceShort;

    if (capitalize) {
      return distanceUnit.charAt(0).toUpperCase() + distanceUnit.slice(1);
    }

    return distanceUnit;
  }

  return useLong ? 'miles' : 'mi';
};

/**
 * Returns an object with a summary of the trips that match the provided bands
 * @param {object[]} trips Trips array
 * @param {object[]} bands Rate bands
 * @returns {object} Trip band summary
 */
export const getMatchingBandRatesFromTrips = (trips, bands) => {
  const matchingBands = {};

  bands.forEach(({ bandStart, bandEnd, bandRate }) => {
    const parseBandStart = parseFloat(bandStart);
    const parseBandEnd = parseFloat(bandEnd);
    const parseBandRate = parseFloat(bandRate);

    trips.forEach(trip => {
      const parsedMiles = parseFloat(trip.journeyDistance);

      if (Math.ceil(parsedMiles) >= parseBandStart && Math.ceil(parsedMiles) <= parseBandEnd) {
        const bandName = [bandStart, bandEnd].join('-');

        const tripBandTotal = {
          distance: parsedMiles,
          rate: parseBandRate,
          total: parsedMiles * parseBandRate,
        };

        if (matchingBands[bandName]) {
          matchingBands[bandName].push(tripBandTotal);
        } else {
          matchingBands[bandName] = [tripBandTotal];
        }
      }
    });
  });

  return matchingBands;
};

/**
 * Check if the list of trips contain at least one trip with a deducted commute distance
 * @param {object[]} trips
 * @returns {boolean}
 */
export const hasTripsWithDeductedCommuteMiles = (trips = []) => {
  return trips.some(trip => {
    return ['number', 'string'].includes(typeof trip?.pricing?.commute?.commuteDistance);
  });
};

/**
 * Format address schema into a readable string
 * @param {object} address adress data
 * @returns {string}
 */
export const formatAddressIntoString = address => {
  if (!address) return '-';

  return [
    address.streetOne,
    address.city,
    `${address.state} ${address.country || ''} ${address.postalCode}`,
  ]
    .filter(Boolean)
    .join(', ')
    .replace(/undefined/gi, '');
};

/**
 * @param {number} amount
 */
export const convertKilometersToMiles = amount => {
  return parseFloat(amount) * 0.621371;
};

/**
 * @param {number} amount
 */
export const convertMilesToKilometers = amount => {
  return parseFloat(amount) / 1.60934;
};

/**
 * @param {number} amount
 */
export const convertMetersToMiles = amount => {
  return parseFloat(amount) / 1609.344;
};
/**
 * Check if a trip has additional journey distance.
 * @param {object} trip - The trip object containing journey distance information.
 * @param {number?} trip.additionalJourneyDistance - The additional journey distance for the trip.
 * @returns {boolean} - Returns true if additional journey distance exists and is not zero, otherwise false.
 */
export const checkForAdditionalJourneyDistance = trip => {
  const additionalDistanceValue = parseFloat(get(trip, 'additionalJourneyDistance'));
  return !isNaN(additionalDistanceValue) && additionalDistanceValue !== 0;
};
