import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const InsuranceManagementSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('insuranceManagement')}>
      <FeatureFormItem
        label={t('axleIntegration')}
        value={companySettings.axleIntegration}
        onChange={checked => onFeatureChange({ axleIntegration: checked })}
      />

      <FeatureFormItem
        label={t('measureOneIntegration')}
        value={companySettings.measureOneIntegration}
        onChange={checked => onFeatureChange({ measureOneIntegration: checked })}
      />
    </SettingSectionWrapper>
  );
};

export default InsuranceManagementSettingsFormItems;
