import { Descriptions, Space } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import TripAnomaliesAPI from '../../api/trip-anomalies';
import { Button, ConfirmModal, HelpIcon } from '../../components';
import CompanyLookupSelect from '../../components/CompanyLookupSelect';
import CompanyUsersLookupSelect from '../../components/CompanyUsersLookupSelect';
import ErrorBoundary from '../../components/ErrorBoundary';
import FullScreenTripModal from '../../components/Modal/FullScreenTripModal';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import Table from '../../components/Table';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import TableColumsEnum from '../../enum/TableColumns';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import useModal from '../../hooks/useModal';
import {
  getUserFullName,
  momentFormat,
  momentTimezone,
  setModalFullscreenWidth,
} from '../../utils/common';
import { formatNumberToLocale } from '../../utils/numbers';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';
import { renderCheckmarkFromBoolean } from '../../utils/tables';
import TripAnomaliesMap from './TripAnomaliesMap';

const COMMON_RESPONSE_FILTER_SETTINGS = { xs: 24, md: 12, lg: 4 };

const TripAnomaliesView = props => {
  const { t, history, location } = props;

  const initialFilters = useMemo(
    () => ({
      companyId: null,
      userId: null,
      isReviewed: false,
      fromDate: moment().subtract(1, 'month'),
      toDate: moment(),
    }),
    [],
  );
  const filters = useLocationSearchQueryParser(location, initialFilters);
  const [
    isAnomalyAcceptModalVisible,
    openAnomalyAcceptModal,
    closeAnomalyAcceptModal,
    anomalyAcceptModalProps,
  ] = useModal();
  const [
    isAnomalyRejectModalVisible,
    openAnomalyRejectModal,
    closeAnomalyRejectModal,
    anomalyRejectModalProps,
  ] = useModal();

  const [
    isAnomalyDetailModalVisible,
    openAnomalyDetailModalVisible,
    closeAnomalyDetailModalVisible,
  ] = useModal();
  const [selectedMapConfig, setSelectedMapConfig] = useState({
    routes: [],
    center: [0, 0],
  });

  const {
    query: tripAnomaliesQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      placeholderData: { documents: [], totalCount: 0 },
      select: ({ documents, totalCount }) => ({
        totalCount,
        documents: documents.map(doc => ({ expandable: true, ...doc })),
      }),
      queryKey: ['fetchTripAnomalies', ...Object.values(filters)],
      queryFn: () =>
        new TripAnomaliesAPI().fetchTripAnomalies(
          {
            companyId: filters?.companyId,
            userId: filters?.userId,
            type: filters?.type,
            isReviewed: filters?.isReviewed,
            fromDate: filters?.fromDate ? momentFormat(filters?.fromDate, 'YYYY-MM-DD') : null,
            toDate: filters?.toDate ? momentFormat(filters?.toDate, 'YYYY-MM-DD') : null,
          },
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onError: error => handleApiErrors(error.response),
    },
    { resetPageQueryKey: Object.values(filters) },
  );

  const handleFiltersChange = useCallback(
    filters => replaceCurrentPageSearchQueryParams(history, filters),
    [history],
  );

  const handleAnomalyAcceptMutation = useMutation(
    anomalyId => new TripAnomaliesAPI().reviewTripAnomaly(anomalyId, 'accept'),
    {
      onSuccess: () => {
        closeAnomalyAcceptModal();
        tripAnomaliesQuery.refetch();
        Toast({
          type: 'open',
          message: t('anomalyAcceptedSuccess'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const handleAnomalyRejectMutation = useMutation(
    anomalyId => new TripAnomaliesAPI().reviewTripAnomaly(anomalyId, 'reject'),
    {
      onSuccess: () => {
        closeAnomalyRejectModal();
        tripAnomaliesQuery.refetch();
        Toast({
          type: 'open',
          message: t('anomalyRejectedSuccess'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const TABLE_COLUMNS = useMemo(
    () => [
      TableColumsEnum.USER_PROFILE_NAME({
        t,
        sorter: true,
        key: ['user.firstName', 'user.lastName'].join(','),
        width: 250,
        userProps: {
          linkableUser: false,
        },
      }),
      {
        width: 250,
        title: t('Company'),
        key: 'company',
        dataIndex: ['company', 'name'],
      },
      {
        width: 200,
        title: t('type'),
        key: 'type',
        dataIndex: 'type',
        render: type => <div style={{ minWidth: 'max-content' }}>{t(`anomalies:${type}`)}</div>,
      },
      {
        width: 100,
        title: t('isDiagonalDistanceBelowMinimum'),
        key: 'calculations.isDiagonalDistanceBelowMinimum',
        dataIndex: ['calculations', 'isDiagonalDistanceBelowMinimum'],
        render: (isDiagonalDistanceBelowMinimum, data) => (
          <Space
            direction="horizontal"
            wrap={false}
            align="center"
            style={{ justifyContent: 'center' }}
          >
            <div style={{ display: 'grid', height: 15 }}>
              {renderCheckmarkFromBoolean(isDiagonalDistanceBelowMinimum)}
            </div>
            <div style={{ height: 18 }}>
              <HelpIcon
                hint={
                  <Descriptions bordered size="small" column={1} layout="horizontal">
                    <Descriptions.Item
                      label={t('minimumDiagonalDistanceInKilometers')}
                      contentStyle={{ width: 'max-content' }}
                    >
                      {get(data, 'calculations.minimumDiagonalDistanceInKilometers')
                        ? formatNumberToLocale(
                            get(data, 'calculations.minimumDiagonalDistanceInKilometers'),
                          )
                        : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{ width: 'max-content' }}
                      label={t('diagonalDistance')}
                    >
                      {get(data, 'calculations.diagonalDistance')
                        ? formatNumberToLocale(get(data, 'calculations.diagonalDistance'))
                        : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </div>
          </Space>
        ),
      },
      {
        width: 100,
        title: t('isInefficientRoute'),
        key: 'calculations.isInefficientRoute',
        dataIndex: ['calculations', 'isInefficientRoute'],
        render: (isInefficientRoute, data) => (
          <Space
            direction="horizontal"
            wrap={false}
            align="center"
            style={{ justifyContent: 'center' }}
          >
            <div style={{ display: 'grid', height: 15 }}>
              {renderCheckmarkFromBoolean(isInefficientRoute)}
            </div>
            <div style={{ height: 18 }}>
              <HelpIcon
                hint={
                  <Descriptions bordered size="small" column={1} layout="horizontal">
                    <Descriptions.Item
                      contentStyle={{ width: 'max-content' }}
                      label={t('minimumRouteEfficiency')}
                    >
                      {get(data, 'calculations.minimumRouteEfficiency')
                        ? formatNumberToLocale(get(data, 'calculations.minimumRouteEfficiency'))
                        : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{ width: 'max-content' }}
                      label={t('routeEfficiency')}
                    >
                      {get(data, 'calculations.routeEfficiency')
                        ? formatNumberToLocale(get(data, 'calculations.routeEfficiency'))
                        : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </div>
          </Space>
        ),
      },
      {
        width: 180,
        align: 'right',
        title: t('anomalies:allowedPercentageOfApproximateCoordinates'),
        key: 'allowedPercentageOfApproximateCoordinates',
        dataIndex: ['calculations', 'allowedPercentageOfApproximateCoordinates'],
        render: percent => `${typeof percent === 'number' ? percent : 0}%`,
      },
      {
        width: 180,
        align: 'right',
        title: t('anomalies:minimumDistanceBetweenCoordinates'),
        key: 'minimumDistanceBetweenCoordinates',
        dataIndex: ['calculations', 'minimumDistanceBetweenCoordinates'],
      },
      {
        width: 180,
        align: 'right',
        title: t('anomalies:percentageOfCoordinatesApproximateToTripEnd'),
        key: 'percentageOfCoordinatesApproximateToTripEnd',
        dataIndex: ['calculations', 'percentageOfCoordinatesApproximateToTripEnd'],
        render: percent => `${typeof percent === 'number' ? percent : 0}%`,
      },
      {
        width: 180,
        align: 'right',
        title: t('anomalies:percentageOfCoordinatesApproximateToTripStart'),
        key: 'percentageOfCoordinatesApproximateToTripStart',
        dataIndex: ['calculations', 'percentageOfCoordinatesApproximateToTripStart'],
        render: percent => `${typeof percent === 'number' ? percent : 0}%`,
      },
      {
        width: 150,
        title: t('createdDate'),
        key: 'created',
        dataIndex: 'created',
        render: createdDate => (
          <div style={{ minWidth: 'max-content' }}>
            {momentTimezone(createdDate).format('MM-DD-YYYY')}
          </div>
        ),
      },
      {
        title: '',
        key: 'action',
        render: anomaly => (
          <Space direction="horizontal" wrap={false} size="small">
            <Button
              block
              size="xs"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                openAnomalyAcceptModal({
                  anomaly: anomaly,
                  title: t('acceptAnomaly'),
                  onOk: () => handleAnomalyAcceptMutation.mutateAsync(anomaly._id),
                });
              }}
            >
              {t('accept')}
            </Button>
            <Button
              block
              size="xs"
              type="secondary"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                openAnomalyRejectModal({
                  anomaly: anomaly,
                  title: t('rejectAnomaly'),
                  onOk: () => handleAnomalyRejectMutation.mutateAsync(anomaly._id),
                });
              }}
            >
              {t('reject')}
            </Button>
          </Space>
        ),
      },
    ],
    [
      t,
      handleAnomalyAcceptMutation,
      handleAnomalyRejectMutation,
      openAnomalyAcceptModal,
      openAnomalyRejectModal,
    ],
  );

  return (
    <PageContainer title={t('anomalies:tripAnomalies')}>
      <Space direction="vertical" size="small">
        <PageFiltersRenderer
          loading={tripAnomaliesQuery.isFetching}
          onFiltersChange={handleFiltersChange}
          filters={[
            {
              label: t('date'),
              allowClear: true,
              componentType: 'dateRange',
              name: ['fromDate', 'toDate'],
              responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              value:
                filters.fromDate && filters.toDate
                  ? [moment(filters.fromDate), moment(filters.toDate)]
                  : null,
              defaultValue:
                filters.fromDate && filters.toDate ? [filters.fromDate, filters.toDate] : null,
            },
            {
              componentType: 'select',
              label: t('type'),
              name: 'type',
              defaultValue: filters.type || null,
              value: filters.type,
              responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              options: [
                { label: t('All'), value: null },
                { label: t('anomalies:gps-litter'), value: 'gps-litter' },
                { label: t('anomalies:high-speed'), value: 'high-speed' },
              ],
            },
            {
              componentType: 'select',
              label: t('reviewed'),
              name: 'isReviewed',
              defaultValue: filters.isReviewed || null,
              value: filters.isReviewed,
              responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              options: [
                { label: t('All'), value: null },
                { label: t('yes'), value: true },
                { label: t('no'), value: false },
              ],
            },
            {
              componentType: 'custom',
              label: t('Company'),
              responsive: {
                ...COMMON_RESPONSE_FILTER_SETTINGS,
                lg: 6,
              },
              render: ({ updateFilterValues }) => (
                <CompanyLookupSelect
                  t={t}
                  showAllOption
                  value={filters.companyId}
                  disabled={tripAnomaliesQuery.isFetching}
                  onChange={companyId => updateFilterValues({ companyId, userId: null })}
                />
              ),
            },
            {
              componentType: 'custom',
              label: t('user'),
              responsive: {
                ...COMMON_RESPONSE_FILTER_SETTINGS,
                lg: 6,
              },
              render: ({ updateFilterValues }) => (
                <CompanyUsersLookupSelect
                  t={t}
                  showAllOption
                  key={filters?.companyId}
                  value={filters.userId}
                  companyId={filters?.companyId}
                  disabled={tripAnomaliesQuery.isFetching || !filters?.companyId}
                  onChange={userId => updateFilterValues({ userId })}
                />
              ),
            },
          ]}
        />

        <Table
          withExpandableRows
          showSearchInput={false}
          loading={tripAnomaliesQuery.isFetching}
          scroll={{ x: 1200 }}
          columns={TABLE_COLUMNS}
          dataSource={get(tripAnomaliesQuery, 'data.documents', [])}
          onChange={({ current }) => {
            handlePageChange(current);
          }}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
          expandedRowRender={tripAnomaly => (
            <ErrorBoundary>
              <TripAnomaliesMap
                t={t}
                tripAnomaly={tripAnomaly}
                onViewFullScreenClick={(trp, activeRoutes, mapCenter) => {
                  setSelectedMapConfig({ routes: activeRoutes, center: mapCenter });
                  openAnomalyDetailModalVisible();
                }}
              />
            </ErrorBoundary>
          )}
        />
      </Space>
      <FullScreenTripModal
        visible={isAnomalyDetailModalVisible}
        mapConfig={selectedMapConfig}
        onCancel={() => {
          closeAnomalyDetailModalVisible();
          setSelectedMapConfig({ center: [0, 0], routes: [] });
        }}
      />

      <ConfirmModal
        {...anomalyAcceptModalProps}
        {...anomalyRejectModalProps}
        disabled={handleAnomalyAcceptMutation.isLoading || handleAnomalyRejectMutation.isLoading}
        loading={handleAnomalyAcceptMutation.isLoading || handleAnomalyRejectMutation.isLoading}
        width={setModalFullscreenWidth(611)}
        footer={null}
        okText={t('yes')}
        cancelText={t('no')}
        visible={isAnomalyAcceptModalVisible || isAnomalyRejectModalVisible}
        onCancel={() => {
          closeAnomalyAcceptModal();
          closeAnomalyRejectModal();
        }}
        message={
          <Space direction="vertical" size="middle">
            {isAnomalyAcceptModalVisible && (
              <>
                <Text>{t('sureYouWantToAcceptAnomaly')}</Text>
                <Text>{t('tripWillBeCreatedWithThisData')}</Text>
              </>
            )}

            {isAnomalyRejectModalVisible && (
              <>
                <Text>{t('sureYouWantToRejectAnomaly')}</Text>
                <Text>{t('anomalyWillBeDeleted')}</Text>
              </>
            )}

            <Descriptions
              size="small"
              bordered
              column={1}
              title={t('anomalyDetails')}
              style={{ textAlign: 'left' }}
            >
              <Descriptions.Item label={t('createdDate')}>
                {momentTimezone(
                  get(
                    anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly,
                    'created',
                  ),
                ).format('MM-DD-YYYY')}
              </Descriptions.Item>
              <Descriptions.Item label={t('type')}>
                {t(
                  `anomalies:${get(
                    anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly,
                    'type',
                    '',
                  )}`,
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('user')}>
                {getUserFullName(
                  get(
                    anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly,
                    'user',
                    {},
                  ),
                )}
              </Descriptions.Item>

              <Descriptions.Item label={t('companyName')}>
                {get(
                  anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly,
                  'company.name',
                  '',
                )}
              </Descriptions.Item>
            </Descriptions>

            {(anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly) && (
              <ErrorBoundary>
                <TripAnomaliesMap
                  t={t}
                  tripAnomaly={anomalyAcceptModalProps?.anomaly || anomalyRejectModalProps?.anomaly}
                />
              </ErrorBoundary>
            )}
          </Space>
        }
      />
    </PageContainer>
  );
};

export default withNamespaces(['default', 'anomalies'])(withAuthentication(TripAnomaliesView));
