import { omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { fetchFuturePaymentScheduleDates } from '../../api/group';
import {
  createPaymentScheduleDetails,
  deletePaymentSchedule,
  fetchPaymentScheduleDetails,
  PAYMENT_SCHEDULES_API,
  updatePaymentScheduleDetails,
} from '../../api/paymentSchedule';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import { INTERNAL_LINKS } from '../../enum';
import {
  AD_HOC_SCHEDULE_TYPE,
  CUSTOM_SCHEDULE_TYPE,
  MONTHLY_SCHEDULE_TYPE,
  WEEKLY_SCHEDULE_TYPE,
} from '../../enum/ScheduleTypes';
import { emitFetchTimezones } from '../../stores/actions/common';
import {
  selectStoreCompanyGroupByID,
  selectStoreCompanySettings,
  selectStoreDefaultGroup,
} from '../../utils/storeSelectors';
import {
  validateCustomScheduleParams,
  validateMonthlyScheduleParams,
  validateWeeklyScheduleParams,
} from './payment-schedule-validation';

const usePaymentScheduleDetailsState = ({ t, history, match }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [fullPaymentScheduleData, setFullPaymentScheduleData] = useState();

  const [isUpdating, setIsUpdating] = useState(false);

  const [deleteScheduleModalVisible, setDeleteScheduleModalVisible] = useState(false);
  const [isDeletingSchedule, setIsDeletingSchedule] = useState(false);

  const [futurePaymentDates, setFuturePaymentDates] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsWithUsers, setGroupsWithUsers] = useState([]);

  const [paymentScheduleName, setPaymentScheduleName] = useState();
  const [paymentSchedule, setPaymentSchedule] = useState();

  const { ach_enabled: isAchEnabled, tripApprovalWorkflow } = useSelector(
    selectStoreCompanySettings,
  );
  const defaultGroup = useSelector(selectStoreDefaultGroup);
  const { currentCompany } = useSelector(store => store.common);
  const { groupList } = useSelector(store => store.group);

  const handleDeleteScheduleModalToggle = visible => setDeleteScheduleModalVisible(visible);

  const handleGroupSelect = selected => setSelectedGroups(selected);

  const handlePaymentScheduleStateUpdate = newState => setPaymentSchedule(newState);

  const handlePaymentScheduleNameUpdate = newState => setPaymentScheduleName(newState);

  const loadPaymentSchedule = async () => {
    try {
      const { id } = match.params;
      const { nextPeriod, name, ...data } = await fetchPaymentScheduleDetails(id);
      setPaymentScheduleName(name);
      setFullPaymentScheduleData(data);

      setSelectedGroups(data.groupId.map(group => group._id));
      if (data.paymentInterval === AD_HOC_SCHEDULE_TYPE) {
        setPaymentSchedule({ name: data.name, scheduleType: data.paymentInterval });
      } else {
        setPaymentSchedule(
          omit(
            {
              ...data,
              ...(nextPeriod || {}),
              paymentPeriodStart:
                data?.currentPeriod?.paymentPeriodStart || nextPeriod?.paymentPeriodStart,
              scheduleType: data.paymentInterval,
              tripsSubmissionDeadline:
                data.tripsSubmissionDeadline === null ? 0 : data.tripsSubmissionDeadline,
              managerApprovalDeadline:
                data.managerApprovalDeadline === null ? 0 : data.managerApprovalDeadline,
            },
            !isAchEnabled ? ['achTransferDayOfMonth'] : [],
          ),
        );
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchPaymentScheduleDetailsError'),
        });
      });
    }
  };

  const formatPaymentScheduleForBE = isUpdate => {
    const {
      achTransferDayOfMonth,
      paymentPeriodStart,
      paymentDayOfMonth,
      dayOfWeekForPayment,
      paymentRecurrenceNumber,
      paymentRecurrencePeriod,
      dateForPayment,
      ...commonSettings
    } = paymentSchedule;

    const {
      paymentDateUseBusinessDays,
      tripsSubmissionDeadline,
      tripsSubmissionDeadlineUseBusinessDays,
      managerApprovalDeadline,
      managerApprovalDeadlineUseBusinessDays,
    } = commonSettings;

    if (isUpdate) {
      return {
        name: paymentScheduleName,
        groupsIds: selectedGroups,
      };
    }

    if (paymentSchedule.scheduleType === AD_HOC_SCHEDULE_TYPE) {
      return {
        name: paymentScheduleName,
        paymentInterval: paymentSchedule.scheduleType,
        groupsIds: selectedGroups,
      };
    }

    return omit(
      {
        ...(paymentSchedule.scheduleType === WEEKLY_SCHEDULE_TYPE
          ? { dayOfWeekForPayment, paymentPeriodStart }
          : {}),
        ...(paymentSchedule.scheduleType === MONTHLY_SCHEDULE_TYPE
          ? { achTransferDayOfMonth, paymentDayOfMonth, paymentPeriodStart }
          : {}),
        ...(paymentSchedule.scheduleType === CUSTOM_SCHEDULE_TYPE
          ? { paymentRecurrenceNumber, paymentRecurrencePeriod, dateForPayment, paymentPeriodStart }
          : {}),

        name: paymentScheduleName,
        groupsIds: selectedGroups,
        paymentDateUseBusinessDays: !!paymentDateUseBusinessDays,
        tripsSubmissionDeadline,
        tripsSubmissionDeadlineUseBusinessDays: !!tripsSubmissionDeadlineUseBusinessDays,
        managerApprovalDeadline,
        managerApprovalDeadlineUseBusinessDays: !!managerApprovalDeadlineUseBusinessDays,
        paymentInterval: paymentSchedule.scheduleType,
      },
      !isAchEnabled ? ['achTransferDayOfMonth'] : [],
    );
  };

  /**
   * Determines if future payment dates should be fetched based on the payment schedule.
   * Checks if the payment schedule exists and if the necessary deadlines are valid numbers.
   * Also checks if the schedule type is either monthly, weekly, or custom.
   *
   * @returns {boolean} - Returns true if future payment dates should be fetched, otherwise false.
   */
  const shouldFetchFutureDates = useCallback(() => {
    if (paymentSchedule) {
      if (
        typeof paymentSchedule.tripsSubmissionDeadline !== 'number' ||
        typeof paymentSchedule.managerApprovalDeadline !== 'number'
      )
        return false;

      const IS_MONTHLY =
        paymentSchedule.scheduleType === MONTHLY_SCHEDULE_TYPE &&
        !!paymentSchedule.paymentDayOfMonth;

      const IS_WEEKLY =
        paymentSchedule.scheduleType === WEEKLY_SCHEDULE_TYPE &&
        !!paymentSchedule.dayOfWeekForPayment;

      const IS_CUSTOM =
        paymentSchedule.scheduleType === CUSTOM_SCHEDULE_TYPE &&
        !!paymentSchedule.paymentRecurrenceNumber &&
        !!paymentSchedule.paymentRecurrencePeriod &&
        !!paymentSchedule.dateForPayment;

      return IS_MONTHLY || IS_WEEKLY || IS_CUSTOM;
    }

    return false;
  }, [paymentSchedule]);

  /**
   * Normalizes payment schedule periods into a structured format for calendar display.
   * Maps payment dates to their respective descriptions and colors based on the schedule type.
   *
   * @param {Array<Object>} data - The payment dates data to normalize.
   * @returns {Array<Object>} - Returns an array of normalized date objects with descriptions and colors.
   */
  const normalizePaymentSchedulePeriods = useCallback(
    data => {
      const dates = [];
      const CALENDAR_KEY_SETTINGS = {
        paymentDate: {
          color: 'red',
          description: t(
            [MONTHLY_SCHEDULE_TYPE, WEEKLY_SCHEDULE_TYPE].includes(paymentSchedule.scheduleType) &&
              isAchEnabled
              ? 'paymentPeriodEndDate'
              : 'paymentDate',
          ),
        },
        achPaymentDate: {
          color: 'green',
          description: t('achPaymentDate'),
        },
        dateOfTripSubmissionDeadline: {
          color: 'blue',
          description: t('tripSubmissionDay'),
        },
        dateOfManagerApprovalDeadline: {
          color: 'yellow',
          description: t('tripApprovalDay'),
        },
        dateOfAchTransfer: {
          color: 'green',
          description: t('achSubmissionDate'),
        },
      };

      data.forEach(paymentDates => {
        const repeatedDates = {};

        Object.keys(paymentDates).forEach(key => {
          const date = paymentDates[key].match(/\w{2}\/\w{2}\/\w{4}/gi, '')[0] + ' 23:59:59';
          if (!CALENDAR_KEY_SETTINGS[key]) return;

          if (repeatedDates[date]) {
            repeatedDates[date].descriptions.push(
              <Text>{CALENDAR_KEY_SETTINGS[key].description}</Text>,
            );
          } else {
            repeatedDates[date] = {};
            repeatedDates[date].color = CALENDAR_KEY_SETTINGS[key].color;
            repeatedDates[date].descriptions = [
              <Text>{CALENDAR_KEY_SETTINGS[key].description}</Text>,
            ];
          }
        });

        Object.keys(repeatedDates).forEach(date => {
          dates.push({
            date: date,
            description: repeatedDates[date].descriptions,
            color: repeatedDates[date].color,
          });
        });
      });

      return dates;
    },
    [t, isAchEnabled, paymentSchedule],
  );

  const futurePaymentDatesQuery = useQuery({
    enabled: !match?.params?.id && shouldFetchFutureDates(),
    queryKey: [
      'fetchPaymentScheduleDetails',
      match?.params?.id,
      paymentSchedule ? JSON.stringify(formatPaymentScheduleForBE()) : '',
    ],
    queryFn: () => fetchFuturePaymentScheduleDates(formatPaymentScheduleForBE()),
    onSuccess: data => {
      setFuturePaymentDates(normalizePaymentSchedulePeriods(data.nextPaymentScheduleDates));
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          duration: 10, // seconds
          type: 'error',
          message: t('fetchFuturePaymentScheduleDatesError'),
        });
      });
    },
  });

  const previousAndFuturePaymentSchedulePeriodsQuery = useQuery({
    enabled: !!match?.params?.id && !!paymentSchedule,
    placeholderData: [],
    queryKey: ['fetchPaymentPeriodsByScheduleId', match?.params?.id, true],
    queryFn: () =>
      PAYMENT_SCHEDULES_API.fetchPaymentPeriodsByScheduleId(
        match?.params?.id,
        undefined,
        undefined,
        true,
      ),
    onSuccess: ({ documents }) => {
      setFuturePaymentDates(normalizePaymentSchedulePeriods(documents));
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          duration: 10, // seconds
          type: 'error',
          message: t('fetchFuturePaymentScheduleDatesError'),
        });
      });
    },
  });

  const handleSubmit = async () => {
    const { id } = match.params;

    if (!selectedGroups.length) {
      Toast({
        type: 'error',
        message: t('mustSelectOneGroup'),
      });
      return;
    }

    let isValid = true;
    if (paymentSchedule.scheduleType === WEEKLY_SCHEDULE_TYPE) {
      isValid = validateWeeklyScheduleParams(paymentSchedule);
    } else if (paymentSchedule.scheduleType === MONTHLY_SCHEDULE_TYPE) {
      isValid = validateMonthlyScheduleParams(paymentSchedule);
    } else if (paymentSchedule.scheduleType === CUSTOM_SCHEDULE_TYPE) {
      isValid = validateCustomScheduleParams(paymentSchedule);
    }

    if (!isValid) return;

    setIsUpdating(true);

    try {
      if (id) {
        await updatePaymentScheduleDetails(id, formatPaymentScheduleForBE(true));
        // Previous message: You have succesfully set payment schedule.
        Toast({ type: 'success', message: t('paymentScheduleUpdateSuccess') });
      } else {
        await createPaymentScheduleDetails(formatPaymentScheduleForBE());

        Toast({ type: 'success', message: t('paymentScheduleCreateSuccess') });
      }
      queryClient.invalidateQueries(['fetchCompanyPaymentSchedules', currentCompany._id], {
        exact: false,
      });
      history.push(INTERNAL_LINKS.PAYMENT_SCHEDULES);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('paymenScheduleUpdateError'),
        });
      });
    }

    setIsUpdating(false);
  };

  const paymentScheduleUpdater = useCallback(
    data => {
      setPaymentSchedule(currentState => ({ ...currentState, ...data }));
    },
    [setPaymentSchedule],
  );

  const handlePaymentScheduleDelete = async () => {
    setIsDeletingSchedule(true);

    try {
      await deletePaymentSchedule(fullPaymentScheduleData._id);
      queryClient.invalidateQueries(['fetchCompanyPaymentSchedules', currentCompany._id], {
        exact: false,
      });
      history.push(INTERNAL_LINKS.PAYMENT_SCHEDULES);
      Toast({
        type: 'open',
        message: t('paymentScheduleDeleteSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('paymentScheduleDeleteError'),
        });
      });
    }

    setIsDeletingSchedule(false);
  };

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      loadPaymentSchedule();
    }

    dispatch(emitFetchTimezones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let groups = [];

    if (Array.isArray(fullPaymentScheduleData?.groupId)) {
      groups = fullPaymentScheduleData.groupId.filter(groupData => {
        const fullGroup = selectStoreCompanyGroupByID(groupData._id);
        return !!fullGroup?.users?.length;
      });
    }

    setGroupsWithUsers(groups);
  }, [fullPaymentScheduleData]);

  return {
    // Booleans
    fullPaymentScheduleData,
    paymentSchedule,
    isUpdating,
    isLoadingFuturePayments:
      futurePaymentDatesQuery.isFetching || previousAndFuturePaymentSchedulePeriodsQuery.isFetching,
    deleteScheduleModalVisible,
    isDeletingSchedule,
    futurePaymentDates,
    tripApprovalWorkflow,
    defaultGroup,
    // General
    currentCompany,
    companyGroups: groupList,
    selectedGroups,
    paymentScheduleName,
    groupsWithUsers,
    // Handlers
    handleGroupSelect,
    handlePaymentScheduleNameUpdate,
    handleDeleteScheduleModalToggle,
    handlePaymentScheduleStateUpdate,
    handleSubmit,
    paymentScheduleUpdater,
    handlePaymentScheduleDelete,
  };
};

export default usePaymentScheduleDetailsState;
