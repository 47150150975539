import { Form } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  deleteInsuranceDocumentImage,
  downloadInsuranceDocumentImage,
} from '../../api/insurance-policies';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { setupPolicyDocumentsUploadList } from '../../utils/insurancePolicies';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
  useStoreSelector,
} from '../../utils/storeSelectors';
import UploadedFilesTable from '../Table/UploadedFilesTable';
import Text from '../Text';
import Toast from '../Toast';
import UserInsuranceForm from './UserInsuranceForm';

/**
 * UserInsuranceContent component handles the display and management of user insurance documents.
 *
 * @param {Object} props - The component props.
 * @param {function} props.t - Translation function.
 * @param {Object} props.user - The user object containing user details.
 * @param {Object} props.insurancePolicy - The user's insurance policy.
 * @param {boolean} props.disabled - Indicates if the form is disabled.
 * @param {boolean} props.isManualInsuranceEnabled - Indicates if manual insurance is enabled.
 * @param {boolean} props.isSubmitting - Indicates if the form is submitting.
 * @param {Object} props.userComplianceDetails - User compliance details.
 * @param {function} props.onSubmit - Function to call on form submission.
 * @param {function} props.onConfirm - Function to call on confirming unchanged information.
 * @param {boolean} props.isSelectingInsuranceCountry - Indicates if the user is selecting an insurance country.
 * @returns {JSX.Element} The rendered component.
 */
const UserInsuranceContent = ({
  t,
  user,
  insurancePolicy,
  disabled,
  isManualInsuranceEnabled,
  isSubmitting,
  userComplianceDetails,
  onSubmit,
  onConfirm,
  isSelectingInsuranceCountry,
}) => {
  const companySettings = useStoreSelector(selectStoreCompanySettings);

  const [form] = Form.useForm();
  const [documentsToCreateList, setDocumentsToCreateList] = useState([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const currentAuthUser = useStoreSelector(selectStoreCurrentAuthUser);
  const isSameUser = currentAuthUser.profile._id === user._id;

  const [documentList, setImageList] = useState(
    setupPolicyDocumentsUploadList(insurancePolicy?.policyDocs),
  );

  /**
   * Handles changes to the uploaded files.
   *
   * @param {Object} filesInfo - Information about the uploaded files.
   */
  const handleFileChange = filesInfo => {
    const { file, fileList } = filesInfo;
    const { status } = file;

    if (['removed', 'error'].includes(status)) {
      setIsUploadingFile(false);
    }

    if (status === 'error') {
      setImageList(fileList.filter(item => item.status !== 'error'));
      handleApiErrors(file.response, () => {
        Toast({
          type: 'error',
          message: t('errorWhileUploading_file', { filename: file.name }),
        });
      });
    }

    if (['removed', 'uploading'].includes(status)) {
      setImageList(fileList);
    }

    if (status === 'uploading') {
      setIsUploadingFile(true);
    }

    if (status === 'done') {
      setImageList(setupPolicyDocumentsUploadList(file.response.data.policyDocs));
      Toast({
        type: 'open',
        message: t('file_uploadSuccess', { filename: file.name }),
      });

      setIsUploadingFile(false);
    }
  };

  /**
   * Handles changes to the files that are to be created.
   *
   * @param {Object} options - Options containing file information.
   */
  const handleFileToCreateChange = options => {
    const { file, fileList } = options;
    const { status } = file;

    const fileIsInList = documentsToCreateList.find(fl => fl.uid === file.uid);

    if (status === 'uploading' && !fileIsInList) {
      setDocumentsToCreateList(state => {
        return [...state, { ...file, status: 'done' }];
      });
    }

    if (status === 'removed') {
      setDocumentsToCreateList(fileList);
    }
  };

  /**
   * Handles the removal of a file.
   *
   * @param {Object} file - The file to be removed.
   */
  const handleFileRemove = async file => {
    try {
      const response = await deleteInsuranceDocumentImage(insurancePolicy?._id, file.uid);
      // onUserUpdate({ ...userDetails, personalInfor: response.personalInfor });
      setImageList(setupPolicyDocumentsUploadList(response.policyDocs));
      Toast({
        type: 'open',
        message: t('file_deleteSuccess', { filename: file.name }),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  /**
   * Handles the download of a file.
   *
   * @param {Object} file - The file to be downloaded.
   */
  const handleFileDownload = async file => {
    try {
      await downloadInsuranceDocumentImage(insurancePolicy?._id, file.uid, file.name);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  useDidUpdateEffect(() => {
    if (Array.isArray(insurancePolicy?.policyDocs)) {
      const formattedDocs = setupPolicyDocumentsUploadList(insurancePolicy.policyDocs);
      setImageList(formattedDocs);
    }
  }, [insurancePolicy]);

  return (
    <section>
      <UserInsuranceForm
        t={t}
        disabled={disabled || (companySettings.axleIntegration && !isManualInsuranceEnabled)}
        hideInsuranceDocUpload={companySettings.axleIntegration && !isManualInsuranceEnabled}
        isSubmitting={isSubmitting}
        form={form}
        user={user}
        userComplianceDetails={userComplianceDetails}
        insurancePolicy={insurancePolicy}
        onSubmit={onSubmit}
        isUploadingFile={isUploadingFile}
        documentList={documentList}
        documentsToCreateList={documentsToCreateList}
        onFileChange={handleFileChange}
        onFileToCreateChange={handleFileToCreateChange}
        onConfirmInfoIsUnchanged={onConfirm}
      />

      {!isSelectingInsuranceCountry && Array.isArray(insurancePolicy?.policyDocs) && (
        <section>
          <Text variant="h5" style={{ marginTop: 30 }}>
            {t('insuranceDocumentsHistory')}
          </Text>

          <UploadedFilesTable
            t={t}
            loading={isUploadingFile}
            dataSource={documentList}
            actions={{
              canDownload: true,
              download: handleFileDownload,
              canRemove: isSameUser,
              remove: handleFileRemove,
            }}
          />
        </section>
      )}
    </section>
  );
};

export default UserInsuranceContent;
