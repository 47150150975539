import { Space } from 'antd';
import { get } from 'lodash';
import React from 'react';

import { IMAGES, STATUS_LIST } from '../../enum';
import Box from '../Box';
import HelpIcon from '../HelpIcon';
import StatusTag from '../Tag/StatusTag';
import Text from '../Text';

const AxleConnectionStatus = ({ t, axleConnectionInfo }) => {
  if (!axleConnectionInfo || !get(axleConnectionInfo, 'status')) return null;

  const isConnectionActive = get(axleConnectionInfo, 'status') === STATUS_LIST().Status.ACTIVE;

  return (
    <Box padding="10px" maxWidth="max-content">
      <Space align="center" size="middle" direction="horizontal" wrap={false}>
        <img src={IMAGES.AXLE_LOGO} alt="Axle" height="15px" />

        <Space align="center" size="small" direction="horizontal" wrap={false}>
          <StatusTag
            status={isConnectionActive ? 'connected' : get(axleConnectionInfo, 'status')}
          />

          {!isConnectionActive && (
            <HelpIcon
              iconStyle={{ display: 'block' }}
              hint={
                <div>
                  <Text>{t(`axle-${get(axleConnectionInfo, 'status')}-1`)}</Text>
                  <Text>{t(`axle-${get(axleConnectionInfo, 'status')}-2`)}</Text>
                </div>
              }
            />
          )}
        </Space>
      </Space>
    </Box>
  );
};

export default AxleConnectionStatus;
