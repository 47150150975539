import { Col, Row } from 'antd';
import { find, get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, ConfirmModal } from '../../../components';
import AdjustmentHistoryModal from '../../../components/Modal/AdjustmentHistoryModal';
import FullScreenTripModal from '../../../components/Modal/FullScreenTripModal';
import ReceiptApproveConfirmation from '../../../components/Modal/ReceiptApproveConfirmation';
import ReceiptDeleteConfirmationModal from '../../../components/Modal/ReceiptDeleteConfirmationModal';
import ReceiptRejectConfirmation from '../../../components/Modal/ReceiptRejectConfirmation';
import ReceiptStatusHistoryModal from '../../../components/Modal/ReceiptStatusHistoryModal';
import ReimbursementReceiptConfirmationWithMoreInfoTripModal from '../../../components/Modal/ReimbursementReceiptConfirmationWithMoreInfoTripModal';
import SubmitReceiptsForApprovalConfirmation from '../../../components/Modal/SubmitReceiptsForApprovalConfirmation';
import TripsCommentsModal from '../../../components/Modal/TripsCommentsModal';
import PageBreadcrumbs from '../../../components/PageBreadcrumbs';
import ReceiptSummaryCard from '../../../components/Receipts/ReceiptSummaryCard';
import FixedReceiptDetailsTable from '../../../components/Table/FixedReceiptDetailsTable';
import TripsTable from '../../../components/Table/TripsTable';
import StatusTag from '../../../components/Tag/StatusTag';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Tooltip from '../../../components/Tooltip';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS, RECEIPT_TYPES, STATUS_LIST } from '../../../enum';
import useRetryMassTransactionMutation from '../../../hooks/mutations/useRetryMassTransactionMutation';
import useTripsComments from '../../../hooks/useTripsComments';
import { formatPageTitle } from '../../../utils/common';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasUserRole,
} from '../../../utils/roles';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import useReceiptDetailsState from '../../payments/limit-one-receipt-reimbursements/details/useReceiptDetailsState';
import { canAddCommentOnTrip } from '../../trips/trips-permissions';
import { canDeleteReceipt, canUpdateReceiptStatus } from '../receipt-permissions';
import ReceiptFooterAdminActionBoxContent from './ReceiptFooterAdminActionBoxContent';
import ReceiptFooterUserActionBoxContent from './ReceiptFooterUserActionBoxContent';

const TripReceiptDetail = props => {
  const { t, history, match } = props;

  const {
    // Booleans
    isLoading,
    isApprovingReceipt,
    isRejectingTrip,
    isRejectingReceipt,
    isTripDetailsVisible,
    isAdjustmentHistVisible,
    isStatusHistoryVisible,
    isRequestingMoreInfo,
    isProcessingMoreInfoRequest,
    isResubmittingReceiptsForApproval,
    isApprovalConfirmationVisible,
    isApprovalWithMoreInfoVisible,
    isRejectConfirmationVisible,
    isTripRejectConfirmationVisible,
    isResubmitConfirmationVisible,
    isDeleteReceiptModalVisible,

    // General variables
    receipt,
    receiptTrips,
    receiptTripsWithMoreInfo,
    receiptAdjustments,
    tripMapConfig,
    selectedTripsMoreInfo,

    // Handlers & Togglers
    updateReceiptData,
    handleViewTripMapFullScreen,
    handleTripMapClose,
    handleMoreInfoRequest,
    handleApprove,
    handleDeny,
    handleTripDeny,
    handleResubmitForApproval,
    toggleAdjustmentHistoryModal,
    toggleStatusHistoryModal,
    toggleRequestMoreInfo,
    openApprovalConfirmation,
    closeApprovalConfirmation,
    openRejectionConfirmation,
    closeRejectionConfirmation,
    openTripRejectionConfirmation,
    closeTripRejectionConfirmation,
    openResubmitConfirmation,
    closeResubmitConfirmation,
    updateSelectedTripsForMoreInfo,
    updateTripLatestComment,
    openDeleteReceiptModal,
    closeDeleteReceiptModal,
    deleteReceiptMutation,
  } = useReceiptDetailsState({ t, history, receiptID: match.params.id });

  const {
    tripCommentsQuery,
    commentsPaginationConfig,
    tripIdToViewComments,
    isAddingComment,
    isTripCommentsModalVisible,
    openTripCommentsModal,
    closeTripCommentsModal,
    addCommentToTrip,
    handleCommentsPageChange,
  } = useTripsComments({ onAddTripComment: updateTripLatestComment });

  const retryMassTransactionMutation = useRetryMassTransactionMutation(t, {
    onSuccess: () => {
      updateReceiptData({ paymentStatus: STATUS_LIST().Status.PROCESSING });
    },
  });

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const authUserProfile = useSelector(selectStoreCurrentAuthUser);
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);
  const { groupList } = useSelector(store => store.group);

  const CAN_APPROVE_TRIPS =
    hasCompanyManagerOrAdminRole(authUserProfile) &&
    !!companySettings?.settings?.approveReceipt &&
    ![STATUS_LIST().Status.PAID, STATUS_LIST().Status.DENIED].includes(receipt?.status);

  return (
    <PageContainer
      style={{
        paddingBottom:
          canUpdateReceiptStatus(authUserProfile, undefined, true) || isRequestingMoreInfo
            ? 45
            : undefined,
      }}
      sideActionComponent={
        receipt &&
        hasCompanyAdminRole(authUserProfile) && (
          <Tooltip
            placement="left"
            enabled={!canDeleteReceipt(receipt)}
            title={t('onlyFixedReceiptCanBeDeleted')}
          >
            <Button disabled={!canDeleteReceipt(receipt)} onClick={openDeleteReceiptModal}>
              {t('Delete')}
            </Button>
          </Tooltip>
        )
      }
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('Receipts'),
              onClick: () =>
                history.push({
                  pathname: INTERNAL_LINKS.RECEIPTS,
                  search: get(history, 'location.state.goReceiptsQueryParams', ''),
                }),
            },
            receipt
              ? {
                  label: (
                    <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                      <Col>{receipt.seqId || 'SeqID'}</Col>
                      <Col>
                        <StatusTag
                          status={
                            receipt.status === STATUS_LIST().Status.PENDING
                              ? STATUS_LIST().Status.AWAITING_APPROVAL
                              : receipt.status
                          }
                        />
                      </Col>
                      {!isLoading &&
                        Array.isArray(receipt.statusHistory) &&
                        !!receipt.statusHistory.length && (
                          <Col>
                            <LinkText onClick={toggleStatusHistoryModal}>
                              {t('statusHistory')}
                            </LinkText>
                          </Col>
                        )}
                    </Row>
                  ),
                }
              : undefined,
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Receipt Details')}</title>
      </Helmet>

      <Row style={{ marginBottom: 60, width: '100%' }}>
        <Col flex={1}>
          <ReceiptSummaryCard
            t={t}
            company={currentCompany}
            loading={isLoading}
            receipt={receipt}
            hasAdjustments={!!receiptAdjustments.length}
            onAdjustmentView={toggleAdjustmentHistoryModal}
            onRetry={() =>
              retryMassTransactionMutation.mutateAsync(receipt.dwollaCorrelationId._id)
            }
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          {receipt && receipt.type === RECEIPT_TYPES.FIXED_TYPE && (
            <Text variant="h4">{t('fixedAmountDetails')}</Text>
          )}

          {receipt && receipt.type === RECEIPT_TYPES.VARIABLE_TYPE && (
            <Text variant="h4">{t('tripsInReceipt')}</Text>
          )}
        </Col>
      </Row>

      <Row>
        <Col flex={1}>
          {receipt && receipt.type === RECEIPT_TYPES.FIXED_TYPE && (
            <FixedReceiptDetailsTable
              t={t}
              dataSource={[
                {
                  date: receipt.created,
                  paymentFor: receipt.period.paymentPeriodEnd,
                  fixedAmount: receipt.totalAmount,
                  favrInformations: receipt.favrInformations,
                },
              ]}
            />
          )}

          {receipt && receipt.type === RECEIPT_TYPES.VARIABLE_TYPE && (
            <TripsTable
              t={t}
              hideGPSColumns
              authUserID={authUserProfile.profile._id}
              displayAwaitingStatusHelp
              companyCountry={currentCompany.address.country}
              loading={isLoading}
              scroll={{ x: 1400 }}
              dataSource={receiptTrips}
              canRejectTrip={!isRequestingMoreInfo && CAN_APPROVE_TRIPS}
              onTripReject={openTripRejectionConfirmation}
              handleViewFullScreen={handleViewTripMapFullScreen}
              onViewMoreComments={openTripCommentsModal}
              rowSelection={
                isRequestingMoreInfo
                  ? {
                      type: 'checkbox',
                      selectedRowKeys: selectedTripsMoreInfo,
                      getCheckboxProps: item => ({
                        disabled: item.status === STATUS_LIST().Status.MORE_INFO,
                      }),
                      onChange: selectedRowKeys => {
                        if (hasCompanyManagerOrAdminRole(authUserProfile)) {
                          updateSelectedTripsForMoreInfo(selectedRowKeys);
                        }
                      },
                    }
                  : undefined
              }
            />
          )}
        </Col>
      </Row>

      <ReceiptStatusHistoryModal
        t={t}
        title={t('rcStatusHistory')}
        visible={isStatusHistoryVisible}
        statusList={receipt ? receipt.statusHistory : []}
        onCancel={toggleStatusHistoryModal}
      />

      <FullScreenTripModal
        visible={isTripDetailsVisible}
        mapConfig={tripMapConfig}
        onCancel={handleTripMapClose}
      />

      <AdjustmentHistoryModal
        t={t}
        currency={receipt?.currency}
        visible={isAdjustmentHistVisible}
        adjustmentsList={receiptAdjustments}
        onCancel={() => toggleAdjustmentHistoryModal()}
      />

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ConfirmModal
          loading={isRejectingTrip}
          visible={isTripRejectConfirmationVisible || isRejectingTrip}
          message={t('sureYouWantToRejectTrips', { count: receiptTripsWithMoreInfo.length })}
          onOk={handleTripDeny}
          onCancel={closeTripRejectionConfirmation}
          okText={t('yes')}
          cancelText={t('no')}
        />
      )}

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ReceiptApproveConfirmation
          t={t}
          visible={isApprovalConfirmationVisible}
          isApproving={isApprovingReceipt}
          onCancel={closeApprovalConfirmation}
          onConfirm={() => handleApprove(receipt._id)}
        />
      )}

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ReceiptRejectConfirmation
          t={t}
          visible={isRejectConfirmationVisible}
          isRejecting={isRejectingReceipt}
          onCancel={closeRejectionConfirmation}
          onConfirm={() => handleDeny(receipt._id)}
          hasTripsWithMoreInfo={receiptTripsWithMoreInfo.length > 0}
        />
      )}

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ReimbursementReceiptConfirmationWithMoreInfoTripModal
          t={t}
          type="receipt"
          visible={isApprovalWithMoreInfoVisible}
          tripsCount={receiptTripsWithMoreInfo.length}
          onCancel={closeApprovalConfirmation}
          isApprovingReceipt={isApprovingReceipt}
          onApproveAllTripsClick={() => handleApprove(match.params.id, 'approveAll')}
          onTripsAwaitingForApprovalClick={() => handleApprove(match.params.id, 'approvePending')}
        />
      )}

      {receipt && (
        <SubmitReceiptsForApprovalConfirmation
          t={t}
          count={1}
          visible={isResubmitConfirmationVisible}
          isSubmitting={isResubmittingReceiptsForApproval}
          onCancel={closeResubmitConfirmation}
          onConfirm={() => handleResubmitForApproval(receipt?._id)}
        />
      )}

      <TripsCommentsModal
        t={t}
        visible={isTripCommentsModalVisible}
        onCancel={closeTripCommentsModal}
        loading={tripCommentsQuery.isFetching}
        comments={tripCommentsQuery.data.documents}
        paginationConfig={commentsPaginationConfig}
        onPageChange={handleCommentsPageChange}
        isAddingComment={isAddingComment}
        onAddComment={addCommentToTrip}
        canAddComment={canAddCommentOnTrip(
          tripIdToViewComments ? find(receiptTrips, { _id: tripIdToViewComments }) : undefined,
        )}
      />

      {receipt &&
        hasCompanyManagerOrAdminRole(authUserProfile) &&
        canUpdateReceiptStatus(authUserProfile, undefined, true) && (
          <ReceiptFooterAdminActionBoxContent
            // General
            t={t}
            isLoading={isLoading}
            loggedInUser={authUserProfile}
            currentCompany={currentCompany}
            companyGroups={groupList}
            // Receipt
            receipt={receipt}
            onApprove={openApprovalConfirmation}
            onDeny={openRejectionConfirmation}
            onResubmitForApprovalFixed={openResubmitConfirmation}
            // More info
            isRequesting={isProcessingMoreInfoRequest}
            isRequestingMoreInfo={isRequestingMoreInfo}
            receiptTrips={receiptTrips}
            selectedTrips={selectedTripsMoreInfo}
            disableRequestMoreInfo={receiptTripsWithMoreInfo.length === receiptTrips.length}
            toggleRequestMoreInfo={toggleRequestMoreInfo}
            onSendToEmployee={handleMoreInfoRequest}
          />
        )}

      {receipt && hasUserRole(authUserProfile) && (
        <ReceiptFooterUserActionBoxContent
          // General
          t={t}
          isLoading={isLoading}
          loggedInUser={authUserProfile}
          // Receipt
          receipt={receipt}
          onResubmitForApprovalFixed={openResubmitConfirmation}
        />
      )}

      {hasCompanyAdminRole(authUserProfile) && (
        <ReceiptDeleteConfirmationModal
          t={t}
          visible={isDeleteReceiptModalVisible}
          loading={deleteReceiptMutation.isLoading}
          onOk={deleteReceiptMutation.mutateAsync}
          onCancel={closeDeleteReceiptModal}
        />
      )}
    </PageContainer>
  );
};

export default withNamespaces()(TripReceiptDetail);
