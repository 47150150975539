export const INSURANCE_COVERAGES_MO_MAP = {
  bodilyInjury: {
    type: 'amount',
  },
  personalInjury: {
    type: 'amount',
  },
  uninsuredMotoristBodilyInjury: {
    type: 'amount',
  },
  uninsuredMotoristPropertyDamage: {
    type: 'amount',
  },
  propertyDamage: {
    type: 'limit',
  },
  optionalBodilyInjuryToOthers: {
    type: 'amount',
  },
  medicalPayments: {
    type: 'limit',
  },
  collisionAmount: {
    type: 'limit',
  },
  comprehensiveAmount: {
    type: 'limit',
  },
  underinsuredMotoristBodilyInjury: {
    type: 'amount',
  },
  underinsuredMotoristPropertyDamage: {
    type: 'limit',
  },
};

export const INSURANCE_COVERAGES_FIELDS = [
  'bodilyInjuryPerPerson',
  'bodilyInjuryPerAccident',
  'propertyDamagePerAccident',
  'personalInjuryAmount',
  'personalInjuryPerPerson',
  'personalInjuryPerAccident',
  'medicalPaymentsPerPerson',
  'medicalPaymentsPerAccident',
  'medicalPaymentsAmount',
  'comprehensiveAmount',
  'collisionAmount',
  'optionalBodilyInjuryToOthersPerPerson',
  'optionalBodilyInjuryToOthersPerAccident',
  'uninsuredMotoristBodilyInjuryPerPerson',
  'uninsuredMotoristBodilyInjuryPerAccident',
  'uninsuredMotoristPropertyDamagePerPerson',
  'uninsuredMotoristPropertyDamagePerAccident',
  'uninsuredMotoristPropertyDamageAmount',
  'underinsuredMotoristBodilyInjuryPerPerson',
  'underinsuredMotoristBodilyInjuryPerAccident',
  'underinsuredMotoristPropertyDamagePerPerson',
  'underinsuredMotoristPropertyDamagePerAccident',
  'underinsuredMotoristPropertyDamageAmount',
];
