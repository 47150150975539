import { map } from 'lodash';

import { formatQueryRange } from '../utils/common';
import { normalizeAxleInsuranceInformation } from '../utils/insurancePolicies';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

const AxleAPI = Object.freeze({
  /**
   * Start Axle ignition
   * @returns {Promise<Object>} - The response from the API
   */
  startIgnition: async () => {
    const url = `${process.env.REACT_APP_HOST_API}axle/ignition`;
    const result = await handleApiCalls('post', url);
    return result.data;
  },

  /**
   * @typedef {Object} InsuranceDocumentInfo
   * @property {boolean} isSelectedForComplianceCheck
   * @property {Array<{ limitPerPerson?: number, code: string, label?: string, limitPerAccident?: number, deductible?: number }>} coverages
   * @property {Array<{ vin: string, make: string, model: string, year: number }>} properties
   * @property {Array<{ firstName: string, lastName: string }>} insureds
   * @property {string} _id
   * @property {string} policyId
   * @property {string} userId
   * @property {string} companyId
   * @property {string} policyNumber
   * @property {string} effectiveDate
   * @property {string} expirationDate
   * @property {Object} address
   * @property {string} address.streetOne
   * @property {string} [address.streetTwo]
   * @property {string} address.city
   * @property {string} address.postalCode
   * @property {string} address.state
   * @property {string} address.country
   * @property {string} carrier
   * @property {Object} axleAccountId
   * @property {Object} axleAccountId.connection
   * @property {string} axleAccountId.connection.status
   * @property {string} axleAccountId.connection.updatedAt
   * @property {string} created
   * @property {string} updated
   * @property {string} refreshedAt
   * @property {number} __v
   */

  /**
   * Fetches the insurance documents information for a specific user.
   * @param {string} userId - The ID of the user whose insurance documents information is to be fetched.
   * @returns {Promise<InsuranceDocumentInfo>} - The response data from the API containing insurance documents information.
   */
  fetchAxleInsuranceDocumentsInformation: async userId => {
    const url = `${process.env.REACT_APP_HOST_API}user/${userId}/axle/informations`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },

  /**
   * @typedef {Object} AxleInsuranceDocumentsResponse
   * @property {Array<{
   *   _id: string,
   *   isSelectedForComplianceCheck: boolean,
   *   createdAt: string,
   *   modifiedAt: string,
   *   refreshedAt: string,
   *   properties: Array<{ vin: string, make: string, model: string, year: number }>
   * }>} documents - The list of insurance documents.
   * @property {number} totalCount - The total number of insurance documents available.
   */

  /**
   * Fetches all axle insurance documents for a specific user.
   * @param {string} userId - The ID of the user whose insurance documents are to be fetched.
   * @param {number} pageNumber - The page number for pagination.
   * @param {number} pageSize - The number of documents per page.
   * @returns {Promise<AxleInsuranceDocumentsResponse>} - The response data containing insurance documents information.
   */
  fetchAllAxleInsuranceDocuments: async (userId, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({ range: formatQueryRange(pageNumber, pageSize) });
    const url = `${process.env.REACT_APP_HOST_API}user/${userId}/axle/policies?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return {
      ...result.data,
      documents: map(result.data?.documents || [], normalizeAxleInsuranceInformation),
    };
  },

  /**
   * Selects an insurance policy for a user.
   * @param {string} policyId - The ID of the policy to be selected.
   * @returns {Promise<void>} - The response from the API after selecting the policy.
   */
  selectInsurancePolicy: async policyId => {
    const url = `${process.env.REACT_APP_HOST_API}axle-policies/select`;
    const response = await handleApiCalls('post', url, { policyId });
    return response.data;
  },

  /**
   * Fetches a preview of the insurance document for a specific policy.
   * @param {string} policyId - The ID of the policy for which the document preview is requested.
   * @returns {Promise<string>} - A promise that resolves to a URL representing the document preview.
   */
  fetchAxleInsuranceDocumentPreview: async policyId => {
    const url = `${process.env.REACT_APP_HOST_API}axle-policies/${policyId}/report`;
    const response = await handleApiCalls('get', url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    return URL.createObjectURL(response.data);
  },
});

export default AxleAPI;
